import { LIGHT_RATES } from "../../redux/slices/lights/api/rate/rate";
import * as styles from "./TextValue.module.scss";

type ValueProps = {
  value?: string;
  valueSize?: "small" | "big";
};

type ExtraValueProps = {
  extraValue?: number;
  extraPostfix?: string;
  extraIcon?: boolean;
  extraRate?: LIGHT_RATES;
};

export type TextValueProps = (
  | (ValueProps & Partial<ExtraValueProps>)
  | (ExtraValueProps & Partial<ValueProps>)
  | (ExtraValueProps & ValueProps)
) & {
  footerText?: string;
  footerLight?: boolean;
  fractionDigits?: number;
};

export const TextValue: React.FC<TextValueProps> = ({
  footerText,
  value,
  valueSize,
  extraValue,
  extraPostfix,
  extraIcon,
  extraRate,
  fractionDigits,
  footerLight,
}) => {
  let prefix = "";
  let extraClass = styles.info;
  let extraValueNode = <span />;

  if (extraValue != null) {
    prefix = extraValue > 0 ? "+" : "-";
    if (extraRate) {
      extraClass = styles[extraRate as unknown as keyof typeof styles];
    }

    extraValueNode = (
      <div className={extraClass}>
        {prefix}
        {Math.abs(extraValue).toFixed(fractionDigits ?? 1)}
        {extraPostfix ? ` ${extraPostfix}` : "%"}
        {extraIcon && (
          <svg
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 12L5.41 13.41L11 7.83V20H13V7.83L18.58 13.42L20 12L12 4L4 12Z"
              className={extraValue > 0 ? styles.iconUp : styles.iconDown}
            />
          </svg>
        )}
      </div>
    );
  }

  if (value == null) {
    return extraValueNode;
  }

  const valueClass = styles[valueSize ?? "small"];

  return (
    <div className={valueClass}>
      {value}
      {extraValueNode}
      {footerText && (
        <div
          className={footerLight ? styles.footerTextLight : styles.footerText}
        >
          {footerText}
        </div>
      )}
    </div>
  );
};
