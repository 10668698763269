import { useCallback, useMemo } from "react";

import { ICols } from "../../../../components/Dynamics/components/table/exportExcel/ExportExcel";
import {
  ILightData,
  ILightRow,
  ILightTableRow,
} from "../../../../core/scheme/olap";
import { useAppSelector } from "../../../hooks";
import {
  REGION_COUNTRY_CODE,
  REGION_DISTRICT_CODES,
} from "../../regions/api/scheme";
import { LIGHT_RATE_FUNCS, LIGHT_RATES } from "../api/rate/rate";
import { LIGHTS_MAP, LIGHTS_MAP_TYPES, LIGHTS_TYPES } from "../api/scheme";
import { ILightReportItem } from "../reportSlice";
import { getRegion } from "../utils";
import { useRegions } from "./useRegions";

export const LIGHT_PLAN = {
  [LIGHTS_TYPES.city]: (row: ILightRow) => row["90"],
  [LIGHTS_TYPES.housing]: (row: ILightRow) => row["8"],
  [LIGHTS_TYPES.mortgage]: (row: ILightRow) =>
    row["105"]?.value > row["106"]?.value ? row["105"] : row["106"],
  [LIGHTS_TYPES.mortgageGov]: (row: ILightRow) => row["192"],
  [LIGHTS_TYPES.mortgageGovBenefits]: (row: ILightRow) => row["195"],
  [LIGHTS_TYPES.mortgageGovFamily]: (row: ILightRow) => row["200"],
  [LIGHTS_TYPES.mortgageFarEast]: (row: ILightRow) => row["205"],
  [LIGHTS_TYPES.mortgageVillage]: (row: ILightRow) => row["273"],
  [LIGHTS_TYPES.mortgagePrimary]: (row: ILightRow) => row["209"],
  [LIGHTS_TYPES.project]: (row: ILightRow) => row["67"],
  [LIGHTS_TYPES.resettlement]: (row: ILightRow) => row["37"],
  [LIGHTS_TYPES.roads]: (row: ILightRow) => row["20"],
  [LIGHTS_TYPES.roadsDead]: (row: ILightRow) => row["256"],
  [LIGHTS_TYPES.sharedConstruction]: (row: ILightRow) => row["120"],
  [LIGHTS_TYPES.stimul]: (row: ILightRow) => row["51"],
  [LIGHTS_TYPES.water]: (row: ILightRow) => row["77"],
  [LIGHTS_TYPES.recoveryVolga]: (row: ILightRow) => row["240"],
  [LIGHTS_TYPES.baikal]: (row: ILightRow) => row["228"],
  [LIGHTS_TYPES.roadsRegional]: (row: ILightRow) => row["129"],
  [LIGHTS_TYPES.roadsInfrastructure]: (row: ILightRow) => row["260"],
};

export const LIGHT_FACT = {
  [LIGHTS_TYPES.city]: (row: ILightRow) => row["91"],
  [LIGHTS_TYPES.housing]: (row: ILightRow) => row["5"],
  [LIGHTS_TYPES.mortgage]: (row: ILightRow) => row["105"],
  [LIGHTS_TYPES.mortgageGov]: (row: ILightRow) => row["191"],
  [LIGHTS_TYPES.mortgageGovBenefits]: (row: ILightRow) => row["196"],
  [LIGHTS_TYPES.mortgageGovFamily]: (row: ILightRow) => row["201"],
  [LIGHTS_TYPES.mortgageFarEast]: (row: ILightRow) => row["206"],
  [LIGHTS_TYPES.mortgageVillage]: (row: ILightRow) => row["274"],
  [LIGHTS_TYPES.mortgagePrimary]: (row: ILightRow) => row["210"],
  [LIGHTS_TYPES.project]: (row: ILightRow) => row["70"],
  [LIGHTS_TYPES.resettlement]: (row: ILightRow) => row["38"],
  [LIGHTS_TYPES.roads]: (row: ILightRow) => row["22"],
  [LIGHTS_TYPES.roadsDead]: (row: ILightRow) => row["257"],
  [LIGHTS_TYPES.roadsRegional]: (row: ILightRow) => row["130"],
  [LIGHTS_TYPES.roadsInfrastructure]: (row: ILightRow) => row["263"],
  [LIGHTS_TYPES.sharedConstruction]: (row: ILightRow) => row["124"],
  [LIGHTS_TYPES.stimul]: (row: ILightRow) => row["54"],
  [LIGHTS_TYPES.water]: (row: ILightRow) => row["78"],
  [LIGHTS_TYPES.recoveryVolga]: (row: ILightRow) => row["241"],
  [LIGHTS_TYPES.baikal]: (row: ILightRow) => row["229"],
};

export const LIGHT_FIELD_DYNAMICS = {
  [LIGHTS_TYPES.city]: "92",
  [LIGHTS_TYPES.housing]: "7",
  [LIGHTS_TYPES.mortgage]: "107",
  [LIGHTS_TYPES.mortgageGov]: "193",
  [LIGHTS_TYPES.mortgageGovBenefits]: "199",
  [LIGHTS_TYPES.mortgageGovFamily]: "204",
  [LIGHTS_TYPES.mortgageFarEast]: "207",
  [LIGHTS_TYPES.mortgageVillage]: "292",
  [LIGHTS_TYPES.mortgagePrimary]: "211",
  [LIGHTS_TYPES.project]: "71",
  [LIGHTS_TYPES.resettlement]: "39",
  [LIGHTS_TYPES.roads]: "23",
  [LIGHTS_TYPES.roadsDead]: "258",
  [LIGHTS_TYPES.roadsRegional]: "131",
  [LIGHTS_TYPES.roadsInfrastructure]: "266",
  [LIGHTS_TYPES.sharedConstruction]: "126",
  [LIGHTS_TYPES.stimul]: "55",
  [LIGHTS_TYPES.water]: "79",
  [LIGHTS_TYPES.recoveryVolga]: "242",
  [LIGHTS_TYPES.baikal]: "230",
};

export const LIGHT_FIELD_DYNAMICS_SORT: Partial<Record<LIGHTS_TYPES, number>> =
  {
    [LIGHTS_TYPES.sharedConstruction]: -1,
  };

export const LIGHT_COLORED_COLS: { [type in LIGHTS_TYPES]?: string[] } = {
  [LIGHTS_TYPES.city]: [LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.city]],
  [LIGHTS_TYPES.housing]: [LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.housing]],
  [LIGHTS_TYPES.mortgage]: [LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.mortgage]],

  // TODO(GASUNP-13241): Временно отключено
  // [LIGHTS_TYPES.mortgageGov]: [LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.mortgageGov]],
  // [LIGHTS_TYPES.mortgageGovBenefits]: [
  //   LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.mortgageGovBenefits],
  // ],
  // [LIGHTS_TYPES.mortgageGovFamily]: [
  //   LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.mortgageGovFamily],
  // ],
  // [LIGHTS_TYPES.mortgageFarEast]: [
  //   LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.mortgageFarEast],
  // ],
  // [LIGHTS_TYPES.mortgageVillage]: [
  //   LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.mortgageVillage],
  // ],
  // [LIGHTS_TYPES.mortgagePrimary]: [
  //   LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.mortgagePrimary],
  // ],

  [LIGHTS_TYPES.project]: [LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.project]],
  [LIGHTS_TYPES.resettlement]: [
    LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.resettlement],
  ],
  [LIGHTS_TYPES.roads]: [LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.roads]],
  [LIGHTS_TYPES.roadsDead]: [LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.roadsDead]],
  [LIGHTS_TYPES.roadsRegional]: [
    LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.roadsRegional],
  ],
  [LIGHTS_TYPES.roadsInfrastructure]: [
    LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.roadsInfrastructure],
  ],
  [LIGHTS_TYPES.sharedConstruction]: [
    LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.sharedConstruction],
  ],
  [LIGHTS_TYPES.stimul]: [LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.stimul]],
  [LIGHTS_TYPES.water]: [LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.water]],
  [LIGHTS_TYPES.sharedConstruction]: [
    LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.sharedConstruction],
  ],
  [LIGHTS_TYPES.recoveryVolga]: [
    LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.recoveryVolga],
  ],
  [LIGHTS_TYPES.baikal]: [LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.baikal]],
};

export const ADDITIONAL_LIGHTS_MAP = [
  [
    { light: LIGHTS_MAP.roads, title: "Светофор №1" },
    { light: LIGHTS_MAP.roadsRegional, title: "Светофор №2" },
    { light: LIGHTS_MAP.roadsInfrastructure, title: "Светофор №3" },
  ],
  [
    { light: LIGHTS_MAP.mortgageGov, title: "Выплата многодетным семьям" },
    { light: LIGHTS_MAP.mortgageGovBenefits, title: "Льготная ипотека" },
    { light: LIGHTS_MAP.mortgageGovFamily, title: "Семейная ипотека" },
    { light: LIGHTS_MAP.mortgageFarEast, title: "Дальневосточная ипотека" },
    { light: LIGHTS_MAP.mortgageVillage, title: "Сельская ипотека" },
  ],
];

export const EXTRA_LIGHTS_MAP = [
  [LIGHTS_MAP.mortgagePrimary],
  [
    LIGHTS_MAP.mortgageGov,
    LIGHTS_MAP.mortgageGovBenefits,
    LIGHTS_MAP.mortgageGovFamily,
    LIGHTS_MAP.mortgageFarEast,
    LIGHTS_MAP.mortgageVillage,
  ],
  [LIGHTS_MAP.roadsDead],
];

export const STICK_LIGHTS_MAP = {
  [LIGHTS_MAP.roads]: [LIGHTS_MAP.roadsDead],
};

export interface AdditionalLight {
  title: string;
  id: string;
}

export interface AdditionalLights {
  selected: AdditionalLight;
  mainLight: string;
  additional: AdditionalLight[];
}

export const useLight = (id: string, withTotal: boolean = false) => {
  const { items, fetching: listFetching } = useAppSelector(
    (state) => state.lights.list
  );
  const { data: totalData, fetching: totalFetching } = useAppSelector(
    (state) => state.lights.total
  );
  const { items: reportData } = useAppSelector((state) => state.lights.reports);
  const { data: totalRatesData, fetching: totalRatesFetching } = useAppSelector(
    (state) => state.lights.totalRates
  );
  const {
    data: dynamicsData,
    additionalData,
    fetching: dynamicsFetching,
  } = useAppSelector((state) => state.lights.dynamics);

  const { regions, districts, country } = useRegions();

  const getLightById = useCallback(
    (lightId: string) => items.find((item) => item.id === lightId),
    [items]
  );

  const light = useMemo(() => getLightById(id), [getLightById, id]);

  const getTotalByLightId = useCallback(
    (lightId: string) => totalData?.[lightId],
    [totalData]
  );

  const total = useMemo(() => {
    if (!light) {
      return undefined;
    }

    const ids = [light.id, ...(STICK_LIGHTS_MAP[light.id] ?? [])];

    return ids.reduce(
      (prev, id) => ({ ...prev, ...getTotalByLightId(id) }),
      {} as ILightRow
    );
  }, [light, getTotalByLightId]);

  const totalReports: ICols | undefined = useMemo(() => {
    if (!total) {
      return undefined;
    }
    if (
      reportData?.filter((item) => Object.keys(total).includes(item.code))
        .length > 0
    ) {
      let sortedReports = reportData
        ?.filter((item) => Object.keys(total).includes(item.code))
        .sort(
          (a, b) =>
            Number(a.attributeVals.SORT_ORDER) -
            Number(b.attributeVals.SORT_ORDER)
        );
      let level5 = sortedReports.map((item) => item.attributeVals.SORT_ORDER);
      let level4 = sortedReports.map((item) => item.attributeVals.UNIT);
      let parentArr: (any | ILightReportItem | undefined)[][] = [sortedReports];
      //получение родительских показателей в двумерный массив
      const getParentArr: any = () => {
        let childArr = parentArr[0].map((i) =>
          i ? reportData.find((item) => item.code === i.parentId[0]) : undefined
        );
        parentArr.unshift(childArr);
        if (
          childArr.reduce(
            (acc, item) => (item ? (acc += parseInt(item?.parentId[0])) : acc),
            0
          ) !== 0
        ) {
          return getParentArr();
        } else {
          return parentArr;
        }
      };
      getParentArr();
      //преобразование матрицы для корректного слияния ячеек
      let parentArrStr = parentArr.map((item) =>
        item.map((i) =>
          i ? (i.attributeVals["Признак отображения"] === "0" ? "-" : i) : ""
        )
      );
      for (let i = parentArrStr.length - 1; i >= 0; i--) {
        for (let j = 0; j < parentArrStr[0].length; j++) {
          if (parentArrStr[i][j] === "") {
            parentArrStr[i + 1][j] === ""
              ? (parentArrStr[i][j] = parentArrStr[i + 2][j])
              : (parentArrStr[i][j] = parentArrStr[i + 1][j]);
          }
          if (parentArrStr[i][j] === "-") {
            parentArrStr[i - 1][j] === ""
              ? (parentArrStr[i][j] = parentArrStr[i - 2][j])
              : (parentArrStr[i][j] = parentArrStr[i - 1][j]);
          }
        }
      }
      //TODO
      let result = parentArrStr
        .map((item) =>
          item.map((i) => (i ? i.attributeVals?.Наименование : ""))
        )
        .concat([level4, level5]);
      //если значения элементов равны друг другу - объединение колонок
      let merges = Array.from(
        new Set(
          parentArrStr.flatMap((i) =>
            i.map((item: ILightReportItem) =>
              JSON.stringify({
                s: {
                  r:
                    parentArrStr.findIndex((i) =>
                      i.indexOf(item) !== -1 ? true : false
                    ) + 2,
                  c: i.indexOf(item) + 2,
                },
                e: {
                  r:
                    parentArrStr.length -
                    parentArrStr
                      .map((x) => x)
                      .reverse()
                      .findIndex((i) =>
                        i.indexOf(item) !== -1 ? true : false
                      ) +
                    1,
                  c: i.lastIndexOf(item) + 2,
                },
              })
            )
          )
        )
      ).map((item) => JSON.parse(item));

      return {
        data: result,
        merges: merges,
      } as ICols;
    } else {
      //временное условие из-за нехватки новых измерений
      let col = Object.keys(total)
        .sort(
          (a, b) =>
            total[a].attributeVals.SORT_ORDER -
            total[b].attributeVals.SORT_ORDER
        )
        .map((item) => total[item]);
      return {
        data: [
          col.map((item) => item.attributeVals.Наименование),
          col.map((item) => item.attributeVals.UNIT),
          col.map((item) => item.attributeVals.SORT_ORDER),
        ],
        merges: [],
      } as ICols;
    }
  }, [total, reportData]);

  const additionalLights = useMemo(() => {
    if (!light) {
      return;
    }

    const list = ADDITIONAL_LIGHTS_MAP.find((list) =>
      list.some((list) => list.light === light.id)
    );

    if (!list) {
      return;
    }

    const result: AdditionalLights = {
      selected: { title: list[0].title, id: list[0].light },
      mainLight: list[0].light,
      additional: [],
    };

    return list.reduce((prev, curr, index) => {
      const obj = { title: list[index].title, id: curr.light };

      if (curr.light === light.id) {
        prev.selected = obj;
      } else {
        prev.additional.push(obj);
      }

      return prev;
    }, result);
  }, [light]);

  const isAdditional = Boolean(
    additionalLights && light && additionalLights.mainLight !== light.id
  );

  const totalRates = useMemo(
    () =>
      totalRatesData && light
        ? {
            [LIGHT_RATES.high]:
              totalRatesData[light.id]?.filter(
                (row) => row.rate === LIGHT_RATES.high
              ) ?? [],
            [LIGHT_RATES.middle]:
              totalRatesData[light.id]?.filter(
                (row) => row.rate === LIGHT_RATES.middle
              ) ?? [],
            [LIGHT_RATES.low]:
              totalRatesData[light.id]?.filter(
                (row) => row.rate === LIGHT_RATES.low
              ) ?? [],
          }
        : undefined,
    [light, totalRatesData]
  );

  const dataTemp = useCallback(
    (
      items: ILightData | undefined,
      dynamicsField: string,
      sortFactor: number,
      lightId: string,
      regionLevel: number
    ) => {
      const data =
        items && Object.values(items)?.[0]?.[dynamicsField]
          ? (Object.keys(items)
              .filter((item) =>
                regionLevel === 0
                  ? !REGION_DISTRICT_CODES.includes(item)
                  : REGION_DISTRICT_CODES.concat(REGION_COUNTRY_CODE).includes(
                      item
                    )
              )
              .sort((regionA, regionB) => {
                if (regionA === REGION_COUNTRY_CODE) {
                  return -1;
                } else if (regionB === REGION_COUNTRY_CODE) {
                  return 1;
                }

                return (
                  sortFactor * (items[regionB][dynamicsField]?.value ?? 0) -
                  sortFactor * (items[regionA][dynamicsField]?.value ?? 0)
                );
              })
              .map((regionId, index) => {
                const key = regionId;
                const sn = index;
                const region =
                  regionLevel === 0
                    ? getRegion(regionId, regions)
                    : getRegion(regionId, districts.concat(country!));

                return {
                  ...items[regionId],
                  key,
                  sn,
                  region,
                } as ILightTableRow;
              })
              .map((row, index, rows) => ({
                ...row,
                rate: LIGHT_RATE_FUNCS[
                  LIGHTS_MAP_TYPES[lightId as keyof typeof LIGHTS_MAP_TYPES]
                ]?.(row, rows),
              })) as ILightTableRow[])
          : undefined;
      return data;
    },
    [regions, districts, country]
  );

  const getDynamicsData = useCallback(
    (lightId: string, items?: ILightData) => {
      if (!total || dynamicsFetching) {
        return undefined;
      }

      const totalRow = {
        ...total,
        key: REGION_COUNTRY_CODE,
        sn: 0,
        region: getRegion(REGION_COUNTRY_CODE, regions),
      } as ILightTableRow;

      const dynamicsField =
        LIGHT_FIELD_DYNAMICS[
          LIGHTS_MAP_TYPES[lightId as keyof typeof LIGHTS_MAP_TYPES]
        ];

      const sortFactor =
        LIGHT_FIELD_DYNAMICS_SORT[
          LIGHTS_MAP_TYPES[lightId as keyof typeof LIGHTS_MAP_TYPES]
        ] ?? 1;

      const data = {
        regionLevel: dataTemp(items, dynamicsField, sortFactor, lightId, 0),
        districtLevel: dataTemp(items, dynamicsField, sortFactor, lightId, 1),
      };

      if (withTotal && data) {
        data.regionLevel?.unshift(totalRow);
        data.districtLevel?.unshift(totalRow);
      }

      return data;
    },
    [dynamicsFetching, dataTemp, regions, total, withTotal]
  );

  const dynamics = useMemo(() => {
    return getDynamicsData(additionalLights?.mainLight ?? id, dynamicsData);
  }, [additionalLights, dynamicsData, getDynamicsData, id]);

  const additionalDynamics = useMemo(
    () =>
      isAdditional
        ? getDynamicsData(additionalLights!.selected.id, additionalData)
        : undefined,
    [additionalData, additionalLights, getDynamicsData, isAdditional]
  );

  return {
    getLightById,
    light,
    additionalLights,
    isAdditional,
    getTotalByLightId,
    total,
    totalRates,
    totalReports,
    dynamics,
    additionalDynamics,
    fetching: listFetching || totalFetching,
    listFetching,
    totalFetching,
    totalRatesFetching,
    dynamicsFetching,
    coloredColumns: light ? LIGHT_COLORED_COLS[light.type] ?? [] : [],
  };
};

export const useLightByType = (type: LIGHTS_TYPES) => {
  const { items } = useAppSelector((state) => state.lights.list);

  return items.find((item) => item.type === type);
};
