import { Spin } from "antd";
import clsx from "clsx";
import moment from "moment";
import { FC } from "react";

import { formatNumber } from "../../../lib/number";
import {
  DATE_DISPLAY_FORMAT,
  LIGHTS_MAP,
  LIGHTS_TYPES,
} from "../../../redux/slices/lights/api/scheme";
import * as styles from "../CardSubjectSummary.module.scss";
import { CardInfo } from "../components/CardInfo";
import { useCardSubjectSummary } from "../hooks/useCardSubjectSummary";
import { ICardSubjectSummaryProps } from "../scheme";

const LIGHT_TYPE = LIGHTS_TYPES.project;
const LIGHT_ID = LIGHTS_MAP[LIGHT_TYPE];

export const CardSubjectSummaryProject: FC<ICardSubjectSummaryProps> = ({
  id,
}) => {
  const { data, total, fetching } = useCardSubjectSummary(id, LIGHT_ID);

  if (fetching) {
    return (
      <div className={styles.loader}>
        <Spin />
      </div>
    );
  }

  return (
    <>
      <span className={styles.title}>
        Сведения предоставлены на{" "}
        {data?.curr["67"]
          ? moment(data.curr["67"].date).format(DATE_DISPLAY_FORMAT)
          : ""}
      </span>
      <div className={styles.cardsInfoWrapper}>
        <CardInfo
          value={total?.sn}
          description="Позиция субъекта в рейтингах"
          rate={total?.rate}
        />
        <CardInfo
          value={
            data?.curr["67"]
              ? formatNumber(data.curr["67"].value, undefined, 1)
              : "-"
          }
          description={`${
            data?.curr["67"]?.attributeVals.UNIT ?? ""
          }, утвержденные БА (ЛБО)`}
        />
        <CardInfo
          value={
            data?.curr["68"]
              ? formatNumber(data.curr["68"].value, undefined, 1)
              : "-"
          }
          description={`${
            data?.curr["94"]?.attributeVals.UNIT ?? ""
          }, заключены контракты`}
        />
        <CardInfo
          value={
            data?.curr["69"]
              ? formatNumber(
                  data.curr["69"].value,
                  data.curr["69"].attributeVals.UNIT,
                  1
                )
              : "-"
          }
          description="% контрактации"
        />
        <CardInfo
          value={
            data?.curr["70"]
              ? formatNumber(data.curr["70"].value, undefined, 1)
              : "-"
          }
          description={`${
            data?.curr["70"]?.attributeVals.UNIT ?? ""
          }, кассовое исполнение`}
        />
        <CardInfo
          value={
            data?.curr["71"]
              ? formatNumber(
                  data.curr["71"].value,
                  data.curr["71"].attributeVals.UNIT,
                  1
                )
              : "-"
          }
          description="% кассового исполнения"
          subRate={total?.rate}
        />
      </div>
      <div>
        <div className={clsx(styles.marginTop48, styles.heading)}>
          Общее количество проектов, шт.
        </div>
        <div className={styles.flex}>
          <div className={styles.width50}>
            <div className={styles.count}>
              {data?.curr["74"]
                ? formatNumber(data.curr["74"]?.value, undefined, 0)
                : "-"}
            </div>
            <span className={styles.info}>
              {moment(data?.curr["74"]?.date).format("YYYY")}
            </span>
          </div>
          <div className={styles.width50}>
            <div className={styles.count}>
              {data?.curr["72"]
                ? formatNumber(data.curr["72"]?.value, undefined, 0)
                : "-"}
            </div>
            <span className={styles.info}>
              {moment(data?.curr["72"]?.date)
                .subtract(1, "year")
                .format("YYYY")}
            </span>
          </div>
        </div>

        <div className={clsx(styles.marginTop48, styles.heading)}>
          Завершенное количество проектов, шт.
        </div>
        <div className={styles.flex}>
          <div className={styles.width50}>
            <div className={styles.count}>
              {data?.curr["188"]
                ? formatNumber(data.curr["188"]?.value, undefined, 0)
                : "-"}
            </div>
            <span className={styles.info}>
              {data?.curr["188"]
                ? moment(data?.curr["188"]?.date).format("YYYY")
                : moment(data?.curr["74"]?.date).format("YYYY")}
            </span>
          </div>
          <div className={styles.width50}>
            <div className={styles.count}>
              {data?.curr["189"]
                ? formatNumber(data.curr["189"]?.value, undefined, 0)
                : "-"}
            </div>
            <span className={styles.info}>
              {data?.curr["189"]
                ? moment(data?.curr["189"]?.date)
                    .subtract(1, "year")
                    .format("YYYY")
                : moment(data?.curr["72"]?.date)
                    .subtract(1, "year")
                    .format("YYYY")}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
