import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import * as FileSaver from "file-saver";
import moment from "moment";
import React, { FC, useMemo } from "react";
import * as XLSX from "xlsx-color";

import { DATE_DISPLAY_FORMAT } from "../../../../../redux/slices/lights/api/scheme";
import * as styles from "../../../Dynamics.module.scss";

export type ICols = {
  data: string[][];
  merges: any[];
};

export interface ITableProps {
  exportDate: string | undefined;
  projectName: string;
  columns?: ICols | undefined;
  coloredCol?: number | undefined;
  coloredRows?: (string | undefined)[];
}

export const ExportExcel: FC<ITableProps> = ({
  exportDate,
  projectName,
  columns,
  coloredCol,
  coloredRows,
}) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const addStyles = useMemo(
    () => (ws: XLSX.WorkSheet) => {
      let highRows = coloredRows
        ? coloredRows.filter((item) => item === "high").length
        : 0;
      let middleRows = coloredRows
        ? coloredRows.filter((item) => item === "middle").length
        : 0;
      let lowRows = coloredRows
        ? coloredRows.filter((item) => item === "low").length
        : 0;
      for (let i in ws) {
        let cell = XLSX.utils.decode_cell(i);
        if (typeof ws[i] != "object") continue;
        let borderStyle = {
          style: "thin",
          color: { rgb: "000000" },
        };
        ws[i].s = {
          font: {
            name: "Times New Roman",
            color: { rgb: "000000" },
          },
          alignment: {
            vertical: "center",
          },
          border: {
            top: borderStyle,
            bottom: borderStyle,
            left: borderStyle,
            right: borderStyle,
          },
          fill: {
            fgColor: { rgb: "ffffff" },
          },
        };
        if (cell.r <= 1) {
          ws[i].s.fill.fgColor = { rgb: "2047c9" };
          ws[i].s.font.color = { rgb: "ffffff" };
          ws[i].s.border = null;
        }
        if (cell.c === 0 || cell.r <= columns!.data.length + 1) {
          ws[i].s.alignment.horizontal = "center";
        }
        if (cell.r > 1 && cell.r <= columns!.data.length + 1) {
          ws[i].s.alignment.wrapText = true;
          ws[i].s.fill.fgColor = { rgb: "eaf3fa" };
        }
        if (cell.r <= columns!.data.length + 2) {
          ws[i].s.font.bold = true;
        }
        if (cell.c === coloredCol && (highRows || middleRows || lowRows)) {
          if (
            cell.r <= highRows + columns!.data.length + 2 &&
            cell.r > columns!.data.length + 2
          ) {
            ws[i].s.fill.fgColor = { rgb: "e7f7e8" };
          }
          if (
            cell.r > highRows + columns!.data.length + 2 &&
            cell.r <= highRows + columns!.data.length + 2 + middleRows
          ) {
            ws[i].s.fill.fgColor = { rgb: "fdf6e3" };
          }
          if (
            cell.r > highRows + columns!.data.length + 2 + middleRows &&
            cell.r <= highRows + columns!.data.length + 3 + middleRows + lowRows
          ) {
            ws[i].s.fill.fgColor = { rgb: "fbe5e5" };
          }
        }
      }
      let range = XLSX.utils.decode_range(ws["!ref"] as string);
      let merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: range.e.c } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: range.e.c } },
        { s: { r: 2, c: 0 }, e: { r: columns!.data.length + 1, c: 0 } },
        { s: { r: 2, c: 1 }, e: { r: columns!.data.length + 1, c: 1 } },
      ];
      ws["!merges"] = merge.concat(columns!.merges);

      ws["!cols"] = [{ wch: 5 }, { wch: 40 }].concat(
        Array(range.e.c - 1).fill({ wch: 18 })
      );

      ws["!rows"] = [{ hpt: 30 }, { hpt: 30 }].concat(
        Array(columns!.data.length - 2).fill({ hpt: 75 })
      );
    },
    [coloredCol, coloredRows, columns]
  );

  const exportToExcel = useMemo(
    () =>
      (
        exportDate: string | undefined,
        projectName: string,
        columns: ICols | undefined
      ) => {
        if (!columns) {
          return;
        }
        let tableExport =
          document.getElementsByTagName("table").length > 2
            ? (document.getElementsByTagName("table")[2] as HTMLTableElement)
            : (document.getElementsByTagName("table")[1] as HTMLTableElement);
        let wsTemp = XLSX.utils.table_to_sheet(tableExport, { raw: true });
        let tableData: string[][] = XLSX.utils.sheet_to_json(wsTemp, {
          header: 1,
          raw: true,
          defval: "-",
        });
        tableData.splice(0, 1);
        let wsHeader = [
          [`Реализация федерального проекта "${projectName}"`],
          [`на ${moment(exportDate).format(DATE_DISPLAY_FORMAT)} года`],
          ["№", " Субъект Российской Федерации"],
        ];
        let ws = XLSX.utils.aoa_to_sheet(
          wsHeader.concat(new Array(columns!.data.length).fill([""]))
        );
        XLSX.utils.sheet_add_aoa(ws, columns.data, {
          origin: "C3",
        });
        XLSX.utils.sheet_add_aoa(ws, tableData, {
          origin: wsHeader.concat(columns.data).length - 1,
        });

        addStyles(ws);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Лист1");
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, projectName + fileExtension);
      },
    [addStyles]
  );
  return (
    <Button
      className={styles.downloadBtn}
      onClick={(e) => exportToExcel(exportDate, projectName, columns)}
    >
      <DownloadOutlined />
    </Button>
  );
};
