import { Spin } from "antd";
import clsx from "clsx";
import moment from "moment";
import { FC } from "react";

import { formatNumber } from "../../../lib/number";
import {
  DATE_DISPLAY_FORMAT,
  LIGHTS_MAP,
  LIGHTS_TYPES,
} from "../../../redux/slices/lights/api/scheme";
import * as styles from "../CardSubjectSummary.module.scss";
import { CardInfo } from "../components/CardInfo";
import { useCardSubjectSummary } from "../hooks/useCardSubjectSummary";
import { ICardSubjectSummaryProps } from "../scheme";

const LIGHT_TYPE = LIGHTS_TYPES.recoveryVolga;
const LIGHT_ID = LIGHTS_MAP[LIGHT_TYPE];

export const CardSubjectSummaryVolga: FC<ICardSubjectSummaryProps> = ({
  id,
}) => {
  const { data, total, fetching } = useCardSubjectSummary(id, LIGHT_ID);

  if (fetching) {
    return (
      <div className={styles.loader}>
        <Spin />
      </div>
    );
  }

  return (
    <>
      <span className={styles.title}>
        Сведения предоставлены на{" "}
        {data?.curr["242"]
          ? moment(data.curr["242"].date).format(DATE_DISPLAY_FORMAT)
          : ""}
      </span>
      <div className={styles.cardsInfoWrapper}>
        <CardInfo
          value={total?.sn}
          description="Позиция субъекта в рейтингах"
          rate={total?.rate}
        />
        <CardInfo
          value={
            data?.curr["240"]
              ? formatNumber(data.curr["240"].value, undefined, 1)
              : "-"
          }
          description="тыс. руб., утвержденные БА (ЛБО)"
        />
        <CardInfo
          value={
            data?.curr["247"]
              ? formatNumber(data.curr["247"].value, undefined, 1)
              : "-"
          }
          description="тыс. руб., заключены контракты"
        />
        <CardInfo
          value={
            data?.curr["248"]
              ? formatNumber(data.curr["248"].value, "%", 1)
              : "-"
          }
          description="% контрактации"
        />
        <CardInfo
          value={
            data?.curr["241"]
              ? formatNumber(data.curr["241"].value, undefined, 1)
              : "-"
          }
          description="тыс. руб., кассовое исполнение"
        />
        <CardInfo
          value={
            data?.curr["242"]
              ? formatNumber(data.curr["242"].value, "%", 1)
              : "-"
          }
          description="% кассового исполнения"
          subRate={total?.rate}
        />
      </div>
      <div>
        <div className={clsx(styles.flex, styles.marginTop48)}>
          <div className={clsx(styles.width50, styles.heading)}>
            В стадии конкурсных процедур
            <div className={styles.flex}>
              <div className={styles.width50}>
                <div className={styles.count}>
                  {data?.curr["245"]
                    ? formatNumber(data.curr["245"].value, undefined, 1)
                    : "-"}
                </div>
                <span className={styles.info}>тыс. руб.</span>
              </div>
              <div className={styles.width50}>
                <div className={styles.count}>
                  {data?.curr["246"]
                    ? formatNumber(data.curr["246"].value, undefined, 1)
                    : "-"}
                </div>
                <span className={styles.info}>%</span>
              </div>
            </div>
          </div>

          <div className={clsx(styles.width50, styles.heading)}>
            Конкурсные процедуры не объявлялись
            <div className={styles.flex}>
              <div className={styles.width50}>
                <div className={styles.count}>
                  {data?.curr["250"]
                    ? formatNumber(data.curr["250"].value, undefined, 1)
                    : "-"}
                </div>
                <span className={styles.info}>тыс. руб.</span>
              </div>
              <div className={styles.width50}>
                <div className={styles.count}>
                  {data?.curr["251"]
                    ? formatNumber(data.curr["251"].value, undefined, 1)
                    : "-"}
                </div>
                <span className={styles.info}>%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
