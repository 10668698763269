import { combineReducers } from "@reduxjs/toolkit";

import districtsSlice, { ILightsDistrictsState } from "./districtsSlice";
import dynamicsRegionSlice, {
  ILightsDynamicsRegionState,
} from "./dynamicsRegionSlice";
import dynamicsSlice, { ILightsDynamicsState } from "./dynamicsSlice";
import listSlice, { ILightsListState } from "./listSlice";
import reportSlice, { ILightReportState } from "./reportSlice";
import totalRatesSlice, { ILightsTotalRateState } from "./totalRatesSlice";
import totalSlice, { ILightsTotalState } from "./totalSlice";

export interface ILightsReducer {
  list: ILightsListState;
  total: ILightsTotalState;
  totalRates: ILightsTotalRateState;
  districts: ILightsDistrictsState;
  dynamics: ILightsDynamicsState;
  dynamicsRegion: ILightsDynamicsRegionState;
  reports: ILightReportState;
}

const reducer = combineReducers<ILightsReducer>({
  list: listSlice.reducer,
  total: totalSlice.reducer,
  totalRates: totalRatesSlice.reducer,
  districts: districtsSlice.reducer,
  dynamics: dynamicsSlice.reducer,
  dynamicsRegion: dynamicsRegionSlice.reducer,
  reports: reportSlice.reducer,
});

export default reducer;
