import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import { Card, Dropdown, Input, Menu, Spin, Table } from "antd";
import { ColumnType } from "antd/lib/table";
import { CompareFn } from "antd/lib/table/interface";
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";

import { ILightIndicator, ILightTableRow } from "../../../../core/scheme/olap";
import { useRouter } from "../../../../hooks/useRouter";
import { valueFromEvent } from "../../../../lib/events";
import { formatNumber } from "../../../../lib/number";
import { valueCompare } from "../../../../lib/sort";
import { LIGHT_RATES } from "../../../../redux/slices/lights/api/rate/rate";
import { useLight } from "../../../../redux/slices/lights/hooks/useLight";
import { prepareUnit } from "../../../../redux/slices/lights/utils";
import { REGIONS_STARTER } from "../../../../redux/slices/regions/api/client";
import { REGION_DISTRICT_CODES } from "../../../../redux/slices/regions/api/scheme";
import { ButtonGroup } from "../../../ButtonGroup/ButtonGroup";
import { CardHeader } from "../../../CardHeader/CardHeader";
import { Empty } from "../../../Empty";
import * as styles from "../../Dynamics.module.scss";
import { ExportExcel } from "./exportExcel/ExportExcel";

export interface IDynamicsTableProps {
  id: string;
}

const RATE2COLOR = {
  [LIGHT_RATES.high]: styles.cellGreen,
  [LIGHT_RATES.middle]: styles.cellYellow,
  [LIGHT_RATES.low]: styles.cellRed,
};

export const DynamicsTable: FC<IDynamicsTableProps> = ({ id }) => {
  const tableRef = useRef<HTMLDivElement>(null);
  const { getPath } = useRouter();
  const {
    total,
    totalReports,
    dynamics,
    additionalDynamics,
    dynamicsFetching,
    coloredColumns,
    additionalLights,
    isAdditional,
    light,
  } = useLight(id);
  const [search, setSearch] = useState("");
  const [headerHeight, setHeaderHeight] = useState<number>();
  const [width, setWidth] = useState(0);
  const [lightLevel, setLevel] = useState(0);

  const dataFetched = isAdditional ? additionalDynamics : dynamics;

  const data =
    lightLevel === 0 ? dataFetched?.regionLevel : dataFetched?.districtLevel;

  const firstDate = total ? total[Object.keys(total)[0]]?.date : undefined;
  const projectName = light ? light.attributeVals["Наименование"] : "";

  const colorRows = data?.map((item) => item.rate?.toString());

  const colorCol = useMemo(() => {
    if (!total) {
      return;
    }
    let res: number | undefined;
    Object.keys(total).map((item) => {
      if (item === coloredColumns[0]) {
        let colorIndex = total[item].attributeVals.SORT_ORDER;
        res = colorIndex + 1;
      }
      return res;
    });
    return res;
  }, [total, coloredColumns]);

  const columns = useMemo(() => {
    const snWidth = 48;
    const regionsWidth = 320;
    let totalWidth = snWidth + regionsWidth;

    const sorter: (colId: string) => CompareFn<ILightTableRow> =
      (colId) => (a, b, sortOrder) => {
        // Поле с суммой всегда остается наверху при сортировке
        if (a.sn === 0) {
          return sortOrder === "ascend" ? -1 : 1;
        } else if (b.sn === 0) {
          return sortOrder === "ascend" ? 1 : -1;
        }

        return colId === "sn"
          ? valueCompare(a.sn, b.sn)
          : valueCompare(a[colId].value, b[colId].value);
      };

    const cols = total
      ? ([
          {
            title: "№",
            showSorterTooltip: false,
            sorter: sorter("sn"),
            key: "sn",
            dataIndex: "sn",
            width: 48,
            fixed: "left",
            render: (val: number) => (val === 0 ? "" : val),
          },
          {
            title: " Субъект Российской Федерации",
            showSorterTooltip: false,
            sorter: sorter("region"),
            key: "region",
            dataIndex: "region",
            width: 320,
            fixed: "left",
            render: (val: ILightIndicator) =>
              val.code === REGIONS_STARTER.code ||
              REGION_DISTRICT_CODES.includes(val.code) ? (
                val.attributeVals.Наименование
              ) : (
                <Link to={getPath(`/regions/${val.code}`)}>
                  {val.attributeVals.Наименование}
                </Link>
              ),
          },
          ...Object.keys(total)
            .sort(
              (a, b) =>
                total[a].attributeVals.SORT_ORDER -
                total[b].attributeVals.SORT_ORDER
            )
            .map((colId) => {
              const width = 216;

              totalWidth += width;

              const isColored = coloredColumns.indexOf(colId) !== -1;

              const render = (val: ILightIndicator, record: ILightTableRow) => {
                if (!record || !record[colId]) {
                  return "";
                }

                const fractionDigits = /м²\/чел/.test(
                  record[colId].attributeVals.UNIT
                )
                  ? 3
                  : 1;

                const text = val?.value
                  ? formatNumber(val.value, undefined, fractionDigits)
                  : "-";

                if (record.sn && isColored) {
                  let colorClassName =
                    record.rate !== undefined ? RATE2COLOR[record.rate] : "";

                  return (
                    <div className={colorClassName}>
                      <div>{text}</div>
                    </div>
                  );
                }

                return text;
              };

              return {
                title: total[colId].attributeVals["Наименование"],
                showSorterTooltip: false,
                sorter: sorter(colId),
                children: [
                  {
                    title: prepareUnit(total[colId].attributeVals.UNIT),
                    align: "center",
                    className: styles.colMeasure,
                    children: [
                      {
                        title: total[colId].attributeVals.SORT_ORDER,
                        key: colId,
                        dataIndex: colId,
                        className: styles.colNum,
                        render,
                      },
                    ],
                  },
                ],
              };
            }),
        ] as ColumnType<ILightTableRow>[])
      : [];

    if (width !== totalWidth) {
      setWidth(totalWidth);
    }

    return cols;
  }, [coloredColumns, getPath, total, width]);

  const filteredData = useMemo(() => {
    if (!data) {
      return [];
    }

    return search === ""
      ? data
      : data.filter((row) =>
          Object.values(row).some(
            (col) =>
              col?.attributeVals?.Наименование &&
              col.attributeVals.Наименование
                .toLowerCase()
                .includes(search.toLowerCase())
          )
        );
  }, [data, search]);

  useEffect(() => {
    if (tableRef?.current) {
      const tableHeader = tableRef?.current.querySelector(
        ".ant-table-header"
      ) as HTMLDivElement | null;

      if (tableHeader) {
        setTimeout(() => setHeaderHeight(tableHeader.offsetHeight));
      }
    }
  }, [tableRef, columns, filteredData]);

  // Выделаем строку РФ
  const totalRowClassName = useCallback(
    (record: ILightTableRow) => (record.sn === 0 ? styles.totalRow : ""),
    []
  );

  // Вычисляем нужную высоту тела таблицы
  const bodyHeight = useMemo(
    () => (headerHeight ? `calc(100vh - ${headerHeight}px` : "100vh"),
    [headerHeight]
  );

  const menu = additionalLights?.additional &&
    additionalLights.additional.length > 0 && (
      <Menu>
        {additionalLights.additional.map((item) => (
          <Menu.Item key={item.id}>
            <Link to={getPath(`/projects/${item.id}`)}>{item.title}</Link>
          </Menu.Item>
        ))}
      </Menu>
    );

  return (
    <Card
      className={styles.card3}
      title={
        <CardHeader
          title={
            <>
              Детализация по субъектам РФ
              {menu && (
                <Dropdown
                  className={styles.additionalLightsSelector}
                  overlay={menu}
                  trigger={["click"]}
                >
                  <Link to="/projects/20000">
                    {additionalLights?.selected.title} <DownOutlined />
                  </Link>
                </Dropdown>
              )}{" "}
              <div className={styles.textCircleIndicatorLow}>Низкий</div>
              <div className={styles.textCircleIndicatorMedium}>Средний</div>
              <div className={styles.textCircleIndicatorHigh}>
                Выше среднего
              </div>
            </>
          }
          right={
            <>
              <ButtonGroup
                value={lightLevel}
                buttons={[
                  { value: 0, children: "Субъекты РФ" },
                  { value: 1, children: "ФО" },
                ]}
                onChange={valueFromEvent(setLevel)}
              />
              <ExportExcel
                exportDate={firstDate}
                projectName={projectName}
                columns={totalReports}
                coloredCol={colorCol}
                coloredRows={colorRows}
              />
              <Input
                className={styles.search}
                prefix={<SearchOutlined />}
                value={search}
                onInput={valueFromEvent(setSearch)}
              />
            </>
          }
          fullSize
        />
      }
    >
      {dynamicsFetching ? (
        <div className={styles.loader} style={{ height: bodyHeight }}>
          <Spin />
        </div>
      ) : (
        <div ref={tableRef}>
          <Table
            id="exportingTable"
            scroll={{ x: width, y: bodyHeight }}
            columns={columns}
            rowClassName={totalRowClassName}
            dataSource={filteredData}
            pagination={false}
            locale={{
              emptyText: <Empty />,
            }}
          />
        </div>
      )}
    </Card>
  );
};
