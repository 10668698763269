import { Spin } from "antd";
import clsx from "clsx";
import moment from "moment";
import { FC, useMemo } from "react";

import { formatNumber } from "../../../lib/number";
import { LIGHT_RATES } from "../../../redux/slices/lights/api/rate/rate";
import {
  DATE_DISPLAY_FORMAT,
  LIGHTS_MAP,
  LIGHTS_TYPES,
} from "../../../redux/slices/lights/api/scheme";
import { TextValue } from "../../TextValue/TextValue";
import * as styles from "../CardSubjectSummary.module.scss";
import { CardInfo } from "../components/CardInfo";
import { useCardSubjectSummary } from "../hooks/useCardSubjectSummary";
import { ICardSubjectSummaryProps } from "../scheme";

const LIGHT_TYPE = LIGHTS_TYPES.mortgage;
const LIGHT_ID = LIGHTS_MAP[LIGHT_TYPE];

export const CardSubjectSummaryMortgage: FC<ICardSubjectSummaryProps> = ({
  id,
}) => {
  const { data, total, fetching } = useCardSubjectSummary(id, LIGHT_ID);

  const rate = useMemo(
    () => (dataVal: string, rateRevert?: boolean) => {
      if (!data) {
        return undefined;
      }

      if (!data.curr[dataVal]) {
        return undefined;
      }

      return rateRevert
        ? data.curr[dataVal].value > 0
          ? LIGHT_RATES.low
          : LIGHT_RATES.high
        : data.curr[dataVal].value > 0
        ? LIGHT_RATES.high
        : data.curr[dataVal].value < 0
        ? LIGHT_RATES.low
        : undefined;
    },
    [data]
  );

  if (fetching) {
    return (
      <div className={styles.loader}>
        <Spin />
      </div>
    );
  }

  return (
    <>
      <span className={styles.title}>
        Сведения предоставлены на{" "}
        {data?.curr["108"]
          ? moment(data.curr["108"].date).format(DATE_DISPLAY_FORMAT)
          : ""}
      </span>
      <div className={styles.cardsInfoWrapper}>
        <CardInfo
          value={total?.sn}
          description="Позиция субъекта в рейтингах"
          rate={total?.rate}
        />
        <CardInfo
          value={
            data?.curr["105"]
              ? formatNumber(data.curr["105"].value, undefined, 1)
              : "-"
          }
          description={`${
            data?.curr["105"]?.attributeVals.UNIT ?? ""
          }, выдано ипотечных кредитов в текущем году`}
          extraValue={data?.curr["107"] ? data?.curr["107"].value : undefined}
          extraRate={total?.rate}
          extraPrefix={true}
        />
        <CardInfo
          value={
            data?.curr["106"]
              ? formatNumber(data.curr["106"].value, undefined, 1)
              : "-"
          }
          description={`${
            data?.curr["106"]?.attributeVals.UNIT ?? ""
          }, выдано ипотечных кредитов в предыдущем году`}
        />
        <CardInfo
          value={
            data?.curr["108"]
              ? formatNumber(data.curr["108"].value, undefined, 1)
              : "-"
          }
          description={`${
            data?.curr["108"]?.attributeVals.UNIT ?? ""
          }, объем ипотечных кредитов в текущем году`}
          extraValue={data?.curr["127"] ? data?.curr["127"].value : undefined}
          extraRate={rate("127")}
          extraPrefix={true}
        />
        <CardInfo
          value={
            data?.curr["109"]
              ? formatNumber(data.curr["109"].value, undefined, 1)
              : "-"
          }
          description={`${
            data?.curr["109"]?.attributeVals.UNIT ?? ""
          }, объем ипотечных кредитов в предыдущем году`}
        />
      </div>
      <div>
        <div className={clsx(styles.flex, styles.marginTop48)}>
          <div className={clsx(styles.width50, styles.heading)}>
            Выдано ипотечных кредитов, первичный рынок,{" "}
            {data?.curr["209"]?.attributeVals.UNIT}
            <div className={styles.flex}>
              <div className={styles.width50}>
                <div className={styles.textValueCount}>
                  <TextValue
                    valueSize="big"
                    value={
                      data?.curr["209"]
                        ? formatNumber(data?.curr["209"].value, undefined, 1)
                        : "-"
                    }
                    extraValue={
                      data?.curr["211"] ? data.curr["211"].value : undefined
                    }
                    extraRate={rate("211")}
                    footerText={moment(data?.curr["209"]?.date).format("YYYY")}
                    footerLight={true}
                  />
                </div>
              </div>
              <div className={styles.width50}>
                <div className={styles.textValueCount}>
                  <TextValue
                    valueSize="big"
                    value={
                      data?.curr["210"]
                        ? formatNumber(data?.curr["210"].value, undefined, 1)
                        : "-"
                    }
                    footerText={moment(data?.curr["210"]?.date)
                      .subtract(1, "year")
                      .format("YYYY")}
                    footerLight={true}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={clsx(styles.width50, styles.heading)}>
            Выдано ипотечных кредитов, вторичный рынок,{" "}
            {data?.curr["212"]?.attributeVals.UNIT}
            <div className={styles.flex}>
              <div className={styles.width50}>
                <div className={styles.textValueCount}>
                  <TextValue
                    valueSize="big"
                    value={
                      data?.curr["212"]
                        ? formatNumber(data?.curr["212"].value, undefined, 1)
                        : "-"
                    }
                    extraValue={
                      data?.curr["214"] ? data.curr["214"].value : undefined
                    }
                    extraRate={rate("214")}
                    footerText={moment(data?.curr["212"]?.date).format("YYYY")}
                    footerLight={true}
                  />
                </div>
              </div>
              <div className={styles.width50}>
                <div className={styles.textValueCount}>
                  <TextValue
                    valueSize="big"
                    value={
                      data?.curr["213"]
                        ? formatNumber(data?.curr["213"].value, undefined, 1)
                        : "-"
                    }
                    footerText={moment(data?.curr["213"]?.date)
                      .subtract(1, "year")
                      .format("YYYY")}
                    footerLight={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={clsx(styles.flex, styles.marginTop48)}>
          <div className={clsx(styles.width50, styles.heading)}>
            Объем ипотечных кредитов, первичный рынок,{" "}
            {data?.curr["215"]?.attributeVals.UNIT}
            <div className={styles.flex}>
              <div className={styles.width50}>
                <div className={styles.textValueCount}>
                  <TextValue
                    valueSize="big"
                    value={
                      data?.curr["215"]
                        ? formatNumber(data?.curr["215"].value, undefined, 1)
                        : "-"
                    }
                    extraValue={
                      data?.curr["217"] ? data.curr["217"].value : undefined
                    }
                    extraRate={rate("217")}
                    footerText={moment(data?.curr["215"]?.date).format("YYYY")}
                    footerLight={true}
                  />
                </div>
              </div>
              <div className={styles.width50}>
                <div className={styles.textValueCount}>
                  <TextValue
                    valueSize="big"
                    value={
                      data?.curr["216"]
                        ? formatNumber(data?.curr["216"].value, undefined, 1)
                        : "-"
                    }
                    footerText={moment(data?.curr["216"]?.date)
                      .subtract(1, "year")
                      .format("YYYY")}
                    footerLight={true}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={clsx(styles.width50, styles.heading)}>
            Объем ипотечных кредитов, вторичный рынок,{" "}
            {data?.curr["218"]?.attributeVals.UNIT}
            <div className={styles.flex}>
              <div className={styles.width50}>
                <div className={styles.textValueCount}>
                  <TextValue
                    valueSize="big"
                    value={
                      data?.curr["218"]
                        ? formatNumber(data?.curr["218"].value, undefined, 1)
                        : "-"
                    }
                    extraValue={
                      data?.curr["220"] ? data.curr["220"].value : undefined
                    }
                    extraRate={rate("220")}
                    footerText={moment(data?.curr["218"]?.date).format("YYYY")}
                    footerLight={true}
                  />
                </div>
              </div>
              <div className={styles.width50}>
                <div className={styles.textValueCount}>
                  <TextValue
                    valueSize="big"
                    value={
                      data?.curr["219"]
                        ? formatNumber(data?.curr["219"].value, undefined, 1)
                        : "-"
                    }
                    footerText={moment(data?.curr["219"]?.date)
                      .subtract(1, "year")
                      .format("YYYY")}
                    footerLight={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={clsx(styles.flex, styles.marginTop48)}>
          <div className={clsx(styles.width50, styles.heading)}>
            Средняя ставка по ИК, первичный рынок,{" "}
            {data?.curr["221"]?.attributeVals.UNIT}
            <div className={styles.flex}>
              <div className={styles.width50}>
                <div className={styles.textValueCount}>
                  <TextValue
                    valueSize="big"
                    value={
                      data?.curr["221"]
                        ? formatNumber(data?.curr["221"].value, "%", 2)
                        : "-"
                    }
                    extraValue={
                      data?.curr["223"] ? data.curr["223"].value : undefined
                    }
                    extraRate={rate("223", true)}
                    extraPostfix="п.п."
                    footerText={moment(data?.curr["221"]?.date).format("YYYY")}
                    footerLight={true}
                    fractionDigits={2}
                  />
                </div>
              </div>
              <div className={styles.width50}>
                <div className={styles.textValueCount}>
                  <TextValue
                    valueSize="big"
                    value={
                      data?.curr["222"]
                        ? formatNumber(data?.curr["222"].value, "%", 2)
                        : "-"
                    }
                    footerText={moment(data?.curr["222"]?.date)
                      .subtract(1, "year")
                      .format("YYYY")}
                    footerLight={true}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={clsx(styles.width50, styles.heading)}>
            Средняя ставка по ИК, вторичный рынок,{" "}
            {data?.curr["224"]?.attributeVals.UNIT}
            <div className={styles.flex}>
              <div className={styles.width50}>
                <div className={styles.textValueCount}>
                  <TextValue
                    valueSize="big"
                    value={
                      data?.curr["224"]
                        ? formatNumber(data?.curr["224"].value, "%", 2)
                        : "-"
                    }
                    extraValue={
                      data?.curr["226"] ? data.curr["226"].value : undefined
                    }
                    extraRate={rate("226", true)}
                    extraPostfix="п.п."
                    footerText={moment(data?.curr["224"]?.date).format("YYYY")}
                    footerLight={true}
                    fractionDigits={2}
                  />
                </div>
              </div>
              <div className={styles.width50}>
                <div className={styles.textValueCount}>
                  <TextValue
                    valueSize="big"
                    value={
                      data?.curr["225"]
                        ? formatNumber(data?.curr["225"].value, "%", 2)
                        : "-"
                    }
                    footerText={moment(data?.curr["225"]?.date)
                      .subtract(1, "year")
                      .format("YYYY")}
                    footerLight={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={clsx(styles.flex, styles.marginTop48)}>
          <div className={clsx(styles.width50, styles.heading)}>
            Средняя ставка по ИК, {data?.curr["110"]?.attributeVals.UNIT}
            <div className={styles.flex}>
              <div className={styles.width50}>
                <div className={styles.textValueCount}>
                  <TextValue
                    valueSize="big"
                    value={
                      data?.curr["110"]
                        ? formatNumber(data?.curr["110"].value, "%", 2)
                        : "-"
                    }
                    extraValue={
                      data?.curr["112"] ? data.curr["112"].value : undefined
                    }
                    extraRate={rate("112", true)}
                    extraPostfix="п.п."
                    footerText={moment(data?.curr["110"]?.date).format("YYYY")}
                    footerLight={true}
                    fractionDigits={2}
                  />
                </div>
              </div>
              <div className={styles.width50}>
                <div className={styles.textValueCount}>
                  <TextValue
                    valueSize="big"
                    value={
                      data?.curr["111"]
                        ? formatNumber(data?.curr["111"].value, "%", 2)
                        : "-"
                    }
                    footerText={moment(data?.curr["111"]?.date)
                      .subtract(1, "year")
                      .format("YYYY")}
                    footerLight={true}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={clsx(styles.width50, styles.heading)}>
            Средняя ставка по ИК выданным под залог ДДУ,{" "}
            {data?.curr["113"]?.attributeVals.UNIT}
            <div className={styles.flex}>
              <div className={styles.width50}>
                <div className={styles.textValueCount}>
                  <TextValue
                    valueSize="big"
                    value={
                      data?.curr["113"]
                        ? formatNumber(data?.curr["113"].value, "%", 2)
                        : "-"
                    }
                    extraValue={
                      data?.curr["115"] ? data.curr["115"].value : undefined
                    }
                    extraRate={rate("115", true)}
                    extraPostfix="п.п."
                    footerText={moment(data?.curr["113"]?.date).format("YYYY")}
                    footerLight={true}
                    fractionDigits={2}
                  />
                </div>
              </div>
              <div className={styles.width50}>
                <div className={styles.textValueCount}>
                  <TextValue
                    valueSize="big"
                    value={
                      data?.curr["114"]
                        ? formatNumber(data?.curr["114"].value, "%", 2)
                        : "-"
                    }
                    footerText={moment(data?.curr["114"]?.date)
                      .subtract(1, "year")
                      .format("YYYY")}
                    footerLight={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
