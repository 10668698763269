import { Spin } from "antd";
import clsx from "clsx";
import moment from "moment";
import { FC } from "react";

import { formatNumber } from "../../../lib/number";
import {
  DATE_DISPLAY_FORMAT,
  LIGHTS_MAP,
  LIGHTS_TYPES,
} from "../../../redux/slices/lights/api/scheme";
import * as styles from "../CardSubjectSummary.module.scss";
import { CardInfo } from "../components/CardInfo";
import { useCardSubjectSummary } from "../hooks/useCardSubjectSummary";
import { ICardSubjectSummaryProps } from "../scheme";

const LIGHT_TYPE = LIGHTS_TYPES.housing;
const LIGHT_ID = LIGHTS_MAP[LIGHT_TYPE];

export const CardSubjectSummaryHousing: FC<ICardSubjectSummaryProps> = ({
  id,
}) => {
  const { data, district, districtRate, total, totals, fetching } =
    useCardSubjectSummary(id, LIGHT_ID);

  if (fetching) {
    return (
      <div className={styles.loader}>
        <Spin />
      </div>
    );
  }

  return (
    <>
      <span className={styles.title}>
        Сведения предоставлены на{" "}
        {data?.curr["5"]
          ? moment(data.curr["5"].date).format(DATE_DISPLAY_FORMAT)
          : ""}
      </span>
      <div className={styles.cardsInfoWrapper}>
        <CardInfo
          value={total?.sn}
          description="Позиция субъекта в рейтингах"
          rate={total?.rate}
        />
        <CardInfo
          value={
            data?.curr["5"]
              ? formatNumber(data.curr["5"]?.value, undefined, 1)
              : "-"
          }
          description="тыс. кв. м. объема ввода жилья"
        />
        <CardInfo
          value={
            data?.curr["6"]
              ? formatNumber(data.curr["6"]?.value, undefined, 3)
              : "-"
          }
          description="кв.м/чел. ввода жилья"
        />
        <CardInfo
          value={
            data?.curr["8"]
              ? formatNumber(data.curr["8"]?.value, undefined, 0)
              : "-"
          }
          description="тыс. кв. м. план ввода жилья по нацпроекту"
        />
        <CardInfo
          value={
            data?.curr["10"]
              ? formatNumber(data.curr["10"]?.value, undefined, 1)
              : "-"
          }
          description="процент исполнения по нацпроекту"
          percent
        />
      </div>
      <div>
        <div className={styles.subHeader}>
          <div className={clsx(styles.flex, styles.marginTop48)}>
            <div className={clsx(styles.width50, styles.heading)}>
              Ввод жилья на текущую дату,{" "}
              {totals?.[LIGHT_ID]?.["5"]?.attributeVals.UNIT}
              <div className={styles.flex}>
                <div className={styles.width50}>
                  <div className={styles.count}>
                    {totals?.[LIGHT_ID]?.["5"]
                      ? formatNumber(totals[LIGHT_ID]["5"]?.value, undefined, 1)
                      : "-"}
                  </div>
                  <span className={styles.info}>РФ</span>
                </div>
                <div className={styles.width50}>
                  <div className={styles.count}>
                    {district?.[LIGHT_ID]?.["5"]
                      ? formatNumber(
                          district[LIGHT_ID]["5"].value,
                          undefined,
                          1
                        )
                      : "-"}
                  </div>
                  <span className={styles.info}>Федеральный округ</span>
                </div>
              </div>
            </div>

            <div className={clsx(styles.width50, styles.heading)}>
              Ввод жилья на текущую дату,{" "}
              {totals?.[LIGHT_ID]?.["6"]?.attributeVals.UNIT}
              <div className={styles.flex}>
                <div className={styles.width50}>
                  <div className={styles.count}>
                    {totals?.[LIGHT_ID]?.["6"]
                      ? formatNumber(totals[LIGHT_ID]["6"]?.value, undefined, 3)
                      : "-"}
                  </div>
                  <span className={styles.info}>РФ</span>
                </div>
                <div className={styles.width50}>
                  <div className={styles.count}>
                    {district?.[LIGHT_ID]?.["6"]
                      ? formatNumber(
                          district[LIGHT_ID]["6"].value,
                          undefined,
                          3
                        )
                      : "-"}
                  </div>
                  <span className={styles.info}>Федеральный округ</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.subHeader}>
          <div className={clsx(styles.flex, styles.marginTop48)}>
            <div className={clsx(styles.width50, styles.heading)}>
              План ввода жилья на текущуий год по нацпроекту,{" "}
              {totals?.[LIGHT_ID]?.["8"]?.attributeVals.UNIT}
              <div className={styles.flex}>
                <div className={styles.width50}>
                  <div className={styles.count}>
                    {totals?.[LIGHT_ID]?.["8"]
                      ? formatNumber(totals[LIGHT_ID]["8"]?.value, undefined, 0)
                      : "-"}
                  </div>
                  <span className={styles.info}>РФ</span>
                </div>
                <div className={styles.width50}>
                  <div className={styles.count}>
                    {district?.[LIGHT_ID]?.["8"]
                      ? formatNumber(
                          district[LIGHT_ID]["8"].value,
                          undefined,
                          0
                        )
                      : "-"}
                  </div>
                  <span className={styles.info}>Федеральный округ</span>
                </div>
              </div>
            </div>

            <div className={clsx(styles.width50, styles.heading)}>
              Процент исполнения по нацпроекту
              <div className={styles.flex}>
                <div className={styles.width50}>
                  <div className={styles.count}>
                    {totals?.[LIGHT_ID]?.["10"]
                      ? formatNumber(
                          totals[LIGHT_ID]["10"]?.value,
                          totals[LIGHT_ID]["10"]?.attributeVals.UNIT,
                          1
                        )
                      : "-"}
                  </div>
                  <span className={styles.info}>РФ</span>
                </div>
                <div className={styles.width50}>
                  <div className={styles.count}>
                    {district?.[LIGHT_ID]?.["10"]
                      ? formatNumber(
                          district[LIGHT_ID]["10"].value,
                          district[LIGHT_ID]["10"].attributeVals.UNIT,
                          1
                        )
                      : "-"}
                  </div>
                  <span className={styles.info}>Федеральный округ</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={clsx(styles.heading, styles.marginTop48)}>
          Изменение в % г/г
        </div>
        <div className={styles.flex}>
          <div className={styles.width33}>
            <div className={styles.count}>
              {totals?.[LIGHT_ID]?.["7"]
                ? formatNumber(
                    totals[LIGHT_ID]["7"]?.value,
                    totals[LIGHT_ID]["7"]?.attributeVals.UNIT,
                    1
                  )
                : "-"}
            </div>
            <span className={styles.info}>РФ</span>
          </div>
          <div className={styles.width33}>
            <div
              className={clsx(
                styles.count,
                styles[districtRate as keyof typeof styles]
              )}
            >
              {district?.[LIGHT_ID]?.["7"]
                ? formatNumber(
                    district[LIGHT_ID]["7"].value,
                    district[LIGHT_ID]["7"]?.attributeVals.UNIT,
                    1
                  )
                : "-"}
            </div>
            <span className={styles.info}>Федеральный округ</span>
          </div>
          <div className={styles.width33}>
            <div
              className={clsx(
                styles.count,
                styles[total?.rate as keyof typeof styles]
              )}
            >
              {data?.curr["7"]
                ? formatNumber(
                    data.curr["7"]?.value,
                    data.curr["7"]?.attributeVals.UNIT,
                    1
                  )
                : "-"}
            </div>
            <span className={styles.info}>Субъект РФ</span>
          </div>
        </div>
      </div>
    </>
  );
};
