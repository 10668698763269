import { Card } from "antd";
import clsx from "clsx";
import { FC } from "react";

import { LIGHT_RATES } from "../../../redux/slices/lights/api/rate/rate";
import * as styles from "./CardInfo.module.scss";

type ExtraValueProps = {
  extraValue?: number;
  extraPostfix?: string;
  extraRate?: LIGHT_RATES;
  extraPrefix?: boolean;
};

interface ICardInf {
  value?: number | string;
  description: string;
  rate?: LIGHT_RATES;
  subRate?: LIGHT_RATES;
  percent?: boolean;
}

export const CardInfo: FC<ICardInf & ExtraValueProps> = ({
  value = "-",
  description,
  rate,
  subRate,
  percent,
  extraValue,
  extraPostfix,
  extraRate,
  extraPrefix,
}) => {
  let prefix = "";
  let extraClass = styles.cardInfoExtraRate;
  let extraValueNode = <span />;

  if (extraValue != null) {
    if (extraRate) {
      extraClass = styles[extraRate as unknown as keyof typeof styles];
    }
    if (extraPrefix) {
      prefix = extraValue > 0 ? "+" : "-";
    }

    extraValueNode = (
      <div className={extraClass}>
        {prefix}
        {Math.abs(extraValue).toFixed(1)}
        {extraPostfix ?? "%"}
      </div>
    );
  }
  return (
    <Card className={styles.cardInfo}>
      <div className={styles.cardInfoNumber}>
        <div
          className={clsx(
            rate && styles[`cardInfoRate-${rate}` as keyof typeof styles]!,
            subRate &&
              styles[`cardInfoSubRate-${subRate}` as keyof typeof styles]!,
            extraValue && styles[`cardInfoRate-margin` as keyof typeof styles]!
          )}
        >
          {percent ? `${value}%` : value}
        </div>
        {extraValueNode}
      </div>
      {description && (
        <div className={styles.cardInfoDescription}>{description}</div>
      )}
    </Card>
  );
};
