import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { IOlapResponseDimensionItemDim } from "../../../core/scheme/olap";
import { getReports } from "./api/client";

export interface ILightReportItem extends IOlapResponseDimensionItemDim {}

export interface ILightReportState {
  items: ILightReportItem[];
  fetching: boolean;
}

export const initialState: ILightReportState = {
  items: [],
  fetching: false,
};

export const extraActions = {
  get: createAsyncThunk("light/reports", async () => await getReports()),
};

const slice = createSlice({
  name: "lightReports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
      })
      .addCase(extraActions.get.fulfilled, (state, action) => {
        state.items = action.payload.map((item) => ({ ...item }));
        state.fetching = false;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.items = [];
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};
export const reducer = slice.reducer;

export default slice;
