import { actions as districts } from "./districtsSlice";
import { actions as dynamicsRegion } from "./dynamicsRegionSlice";
import { actions as dynamics } from "./dynamicsSlice";
import { actions as list } from "./listSlice";
import { actions as report } from "./reportSlice";
import { actions as totalRates } from "./totalRatesSlice";
import { actions as total } from "./totalSlice";

export interface ILightsActions {
  list: typeof list;
  total: typeof total;
  totalRates: typeof totalRates;
  districts: typeof districts;
  dynamics: typeof dynamics;
  dynamicsRegion: typeof dynamicsRegion;
  report: typeof report;
}

const actions: ILightsActions = {
  list,
  total,
  totalRates,
  districts,
  dynamics,
  dynamicsRegion,
  report,
};

export default actions;
