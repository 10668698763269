import { Spin } from "antd";
import moment from "moment";
import { FC, useMemo } from "react";

import { formatNumber } from "../../../lib/number";
import { LIGHT_RATES } from "../../../redux/slices/lights/api/rate/rate";
import {
  DATE_DISPLAY_FORMAT,
  LIGHTS_MAP,
  LIGHTS_TYPES,
} from "../../../redux/slices/lights/api/scheme";
import * as styles from "../CardSubjectSummary.module.scss";
import { CardInfo } from "../components/CardInfo";
import { useCardSubjectSummary } from "../hooks/useCardSubjectSummary";
import { ICardSubjectSummaryProps } from "../scheme";

const LIGHT_TYPE = LIGHTS_TYPES.roads;
const LIGHT_ID = LIGHTS_MAP[LIGHT_TYPE];

export const CardSubjectSummaryRoads: FC<ICardSubjectSummaryProps> = ({
  id,
}) => {
  const { data, total, fetching } = useCardSubjectSummary(id, LIGHT_ID);

  const rate = useMemo(
    () => (dataVal: string) => {
      if (!data) {
        return undefined;
      }

      if (!data.curr[dataVal]) {
        return undefined;
      }

      return data.curr[dataVal].value > 0
        ? LIGHT_RATES.high
        : data.curr[dataVal].value < 0
        ? LIGHT_RATES.low
        : undefined;
    },
    [data]
  );

  if (fetching) {
    return (
      <div className={styles.loader}>
        <Spin />
      </div>
    );
  }

  return (
    <>
      <span className={styles.title}>
        Сведения предоставлены на{" "}
        {data?.curr["24"]
          ? moment(data.curr["24"].date).format(DATE_DISPLAY_FORMAT)
          : ""}
      </span>
      <div className={styles.cardsInfoWrapper}>
        <CardInfo
          value={total?.sn}
          description="Позиция субъекта в рейтингах"
          rate={total?.rate}
        />
        <CardInfo
          value={
            data?.curr["20"]
              ? formatNumber(data.curr["20"].value, undefined, 1)
              : "-"
          }
          description="млн. руб., объем финансирования"
        />
        <CardInfo
          value={
            data?.curr["22"]
              ? formatNumber(data.curr["22"].value, undefined, 1)
              : "-"
          }
          description="млн. руб., кассовое исполнение"
          extraValue={data?.curr["23"] ? data?.curr["23"].value : undefined}
          extraRate={total?.rate}
        />
        <CardInfo
          value={
            data?.curr["29"]
              ? formatNumber(data.curr["29"].value, undefined, 1)
              : "-"
          }
          description="тыс. м2, площадь выполненных дорожных работ, план"
        />
        <CardInfo
          value={
            data?.curr["30"]
              ? formatNumber(data.curr["30"].value, undefined, 1)
              : "-"
          }
          description="тыс. м2, площадь выполненных дорожных работ, факт"
          extraValue={data?.curr["31"] ? data?.curr["31"].value : undefined}
          extraRate={rate("31")}
        />
      </div>
    </>
  );
};
