import { Spin } from "antd";
import clsx from "clsx";
import moment from "moment";
import { FC } from "react";

import { formatNumber } from "../../../lib/number";
import {
  DATE_DISPLAY_FORMAT,
  LIGHTS_MAP,
  LIGHTS_TYPES,
} from "../../../redux/slices/lights/api/scheme";
import * as styles from "../CardSubjectSummary.module.scss";
import { CardInfo } from "../components/CardInfo";
import { useCardSubjectSummary } from "../hooks/useCardSubjectSummary";
import { ICardSubjectSummaryProps } from "../scheme";

const LIGHT_TYPE = LIGHTS_TYPES.water;
const LIGHT_ID = LIGHTS_MAP[LIGHT_TYPE];

export const CardSubjectSummaryWater: FC<ICardSubjectSummaryProps> = ({
  id,
}) => {
  const { data, total, fetching } = useCardSubjectSummary(id, LIGHT_ID);

  if (fetching) {
    return (
      <div className={styles.loader}>
        <Spin />
      </div>
    );
  }

  return (
    <>
      <span className={styles.title}>
        Сведения предоставлены на{" "}
        {data?.curr["82"]
          ? moment(data.curr["82"].date).format(DATE_DISPLAY_FORMAT)
          : ""}
      </span>
      <div className={styles.cardsInfoWrapper}>
        <CardInfo
          value={total?.sn}
          description="Позиция субъекта в рейтингах"
          rate={total?.rate}
        />
        <CardInfo
          value={
            data?.curr["77"]
              ? formatNumber(data.curr["77"].value, undefined, 1)
              : "-"
          }
          description="тыс. руб., утвержденные БА (ЛБО)"
        />
        <CardInfo
          value={
            data?.curr["84"]
              ? formatNumber(data.curr["84"].value, undefined, 1)
              : "-"
          }
          description="тыс. руб., заключены контракты"
        />
        <CardInfo
          value={
            data?.curr["85"]
              ? formatNumber(data.curr["85"].value, undefined, 1)
              : "-"
          }
          description="% контрактации"
          percent
        />
        <CardInfo
          value={
            data?.curr["78"]
              ? formatNumber(data.curr["78"].value, undefined, 1)
              : "-"
          }
          description="тыс. руб., кассовое исполнение"
        />
        <CardInfo
          value={
            data?.curr["79"]
              ? formatNumber(data.curr["79"].value, undefined, 1)
              : "-"
          }
          description="% кассового исполнения"
          subRate={total?.rate}
          percent
        />
      </div>
      <div>
        <div className={clsx(styles.flex, styles.marginTop48)}>
          <div className={clsx(styles.width50, styles.heading)}>
            В стадии конкурсных процедур
            <div className={styles.flex}>
              <div className={styles.width50}>
                <div className={styles.count}>
                  {data?.curr["82"]
                    ? formatNumber(data.curr["82"].value, undefined, 1)
                    : "-"}
                </div>
                <span className={styles.info}>тыс. руб.</span>
              </div>
              <div className={styles.width50}>
                <div className={styles.count}>
                  {data?.curr["83"]
                    ? formatNumber(data.curr["83"].value, undefined, 1)
                    : "-"}
                </div>
                <span className={styles.info}>%</span>
              </div>
            </div>
          </div>

          <div className={clsx(styles.width50, styles.heading)}>
            Конкурсные процедуры не объявлялись
            <div className={styles.flex}>
              <div className={styles.width50}>
                <div className={styles.count}>
                  {data?.curr["86"]
                    ? formatNumber(data.curr["86"].value, undefined, 1)
                    : "-"}
                </div>
                <span className={styles.info}>тыс. руб.</span>
              </div>
              <div className={styles.width50}>
                <div className={styles.count}>
                  {data?.curr["87"]
                    ? formatNumber(data.curr["87"].value, undefined, 1)
                    : "-"}
                </div>
                <span className={styles.info}>%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
