import "./App.less";
import "moment/locale/ru";

import { Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router";

import { setAuthHeader } from "../core/api/client";
import { useDate } from "../hooks/useDate";
import usePrevious from "../hooks/usePrevious";
import { Dynamics } from "../pages/Dynamics/Dynamics";
import { Home } from "../pages/Home/Home";
import { Region } from "../pages/Region/Region";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { actions as tokenActions } from "../redux/slices/auth/tokenSlice";
import { actions as userActions } from "../redux/slices/auth/userSlice";
import lightsActions from "../redux/slices/lights/actions";
import { useRegions } from "../redux/slices/lights/hooks/useRegions";
import * as styles from "./App.module.scss";
import { Breadcrumbs } from "./Breadcrumbs/Breadcrumbs";
import { Header } from "./Header/Header";
import { Login } from "./Login/Login";

moment.locale("ru");

const App = () => {
  const { date } = useDate();
  const dispatch = useAppDispatch();
  const [isInit, setIsInit] = useState(false);
  const { fetching: tokenFetching, access_token } = useAppSelector(
    (state) => state.auth.token
  );
  const { fetching: userFetching, user } = useAppSelector(
    (state) => state.auth.user
  );
  const { fetching: regionsFetching, requestRegions } = useRegions();
  const { items } = useAppSelector((state) => state.lights.list);
  const prevTokenFetching = usePrevious(tokenFetching);
  const prevUserFetching = usePrevious(userFetching);

  useEffect(() => {
    dispatch(tokenActions.getToken());
  }, [dispatch]);

  useEffect(() => {
    if (prevTokenFetching && !tokenFetching) {
      if (access_token) {
        setAuthHeader(access_token);
      }

      if (!user) {
        dispatch(userActions.getAuthorized());
      } else {
        setIsInit(true);
      }
    }
  }, [access_token, dispatch, tokenFetching, prevTokenFetching, user]);

  useEffect(() => {
    if (prevUserFetching && !userFetching) {
      setIsInit(true);
    }
  }, [prevUserFetching, userFetching]);

  useEffect(() => {
    if (isInit && user) {
      requestRegions();
      dispatch<any>(lightsActions.list.get());
    }
  }, [dispatch, isInit, requestRegions, user]);

  useEffect(() => {
    if (items.length > 0) {
      dispatch<any>(
        lightsActions.total.get({ date, ids: items.map((item) => item.id) })
      );
      dispatch<any>(lightsActions.totalRates.get(date));
      dispatch<any>(lightsActions.report.get());
      dispatch<any>(
        lightsActions.districts.get({ date, ids: items.map((item) => item.id) })
      );
    }
  }, [date, dispatch, items, items.length]);

  if (tokenFetching || userFetching || regionsFetching || !isInit) {
    return (
      <div className={styles.app}>
        <header className={styles.header}>
          <Header />
        </header>
        <main className={styles.content}>
          <div className={styles.overlay}>
            <Spin size="large" />
          </div>
        </main>
      </div>
    );
  }

  return (
    <div className={styles.app}>
      <header className={styles.header}>
        <Routes>
          <Route path="/" element={<Header />} />
          <Route path="/:section/:id" element={<Header />} />
        </Routes>
      </header>
      <main className={styles.content}>
        {user ? (
          <>
            <Routes>
              <Route path="/:section/:id" element={<Breadcrumbs />} />
            </Routes>

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/projects/:id" element={<Dynamics />} />
              <Route path="/regions/:id" element={<Region />} />
            </Routes>
          </>
        ) : (
          <div className={styles.overlay}>
            <Login />
          </div>
        )}
      </main>
    </div>
  );
};

export default App;
