import { Spin } from "antd";
import clsx from "clsx";
import moment from "moment";
import { FC } from "react";

import { formatNumber } from "../../../lib/number";
import { LIGHT_RATES } from "../../../redux/slices/lights/api/rate/rate";
import {
  DATE_DISPLAY_FORMAT,
  LIGHTS_MAP,
  LIGHTS_TYPES,
} from "../../../redux/slices/lights/api/scheme";
import { TextValue } from "../../TextValue/TextValue";
import * as styles from "../CardSubjectSummary.module.scss";
import { CardInfo } from "../components/CardInfo";
import { useCardSubjectSummary } from "../hooks/useCardSubjectSummary";
import { ICardSubjectSummaryProps } from "../scheme";

const LIGHT_TYPE = LIGHTS_TYPES.resettlement;
const LIGHT_ID = LIGHTS_MAP[LIGHT_TYPE];

export const CardSubjectSummaryResettlement: FC<ICardSubjectSummaryProps> = ({
  id,
}) => {
  const { data, total, totals, fetching } = useCardSubjectSummary(id, LIGHT_ID);

  if (fetching) {
    return (
      <div className={styles.loader}>
        <Spin />
      </div>
    );
  }

  return (
    <>
      <span className={styles.title}>
        Сведения предоставлены на{" "}
        {data?.curr["45"]
          ? moment(data.curr["45"].date).format(DATE_DISPLAY_FORMAT)
          : ""}
      </span>
      <div className={styles.cardsInfoWrapper}>
        <CardInfo
          value={total?.sn}
          description="Позиция субъекта в рейтингах"
          rate={total?.rate}
        />
        <CardInfo
          value={
            data?.curr["38"]
              ? formatNumber(data.curr["38"].value, undefined, 1)
              : "-"
          }
          description="тыс. м2, факт расселения на 2019-текущий гг. (нарастающим итогом)"
          extraValue={data?.curr["39"] ? data?.curr["39"].value : undefined}
          extraRate={total?.rate}
          extraPrefix={true}
        />
        <CardInfo
          value={
            data?.curr["37"]
              ? formatNumber(data.curr["37"].value, undefined, 1)
              : "-"
          }
          description="тыс. м2, план расселения на 2019-текущий гг."
        />
        <CardInfo
          value={
            data?.curr["41"]
              ? formatNumber(data.curr["41"].value, undefined, 1)
              : "-"
          }
          description="тыс. чел., факт расселения на 2019-текущий гг. (нарастающим итогом)"
          extraValue={data?.curr["42"] ? data?.curr["42"].value : undefined}
          extraPrefix={true}
        />
        <CardInfo
          value={
            data?.curr["40"]
              ? formatNumber(data.curr["40"].value, undefined, 1)
              : "-"
          }
          description="тыс. чел., план расселения на 2019-текущий гг."
        />
      </div>
      <div>
        <div className={clsx(styles.flex, styles.marginTop48)}>
          <div className={clsx(styles.width33, styles.heading)}>
            Российская Федерация
            <div>
              <div className={styles.textValueCount}>
                <TextValue
                  valueSize="big"
                  value={
                    totals?.[LIGHT_ID]?.["38"]
                      ? formatNumber(
                          totals?.[LIGHT_ID]?.["38"].value,
                          undefined,
                          0
                        )
                      : "-"
                  }
                  extraValue={
                    totals?.[LIGHT_ID]?.["39"]
                      ? totals?.[LIGHT_ID]?.["39"].value
                      : undefined
                  }
                  extraRate={LIGHT_RATES.high}
                  footerText="тыс. м2, факт расселения"
                  footerLight={true}
                  fractionDigits={1}
                />
              </div>
              <div className={styles.textValueCount40}>
                <TextValue
                  valueSize="big"
                  value={
                    totals?.[LIGHT_ID]?.["37"]
                      ? formatNumber(
                          totals?.[LIGHT_ID]?.["37"].value,
                          undefined,
                          0
                        )
                      : "-"
                  }
                  footerText="тыс. м2, план расселения"
                  footerLight={true}
                  fractionDigits={1}
                />
              </div>
              <div className={styles.textValueCount40}>
                <TextValue
                  valueSize="big"
                  value={
                    totals?.[LIGHT_ID]?.["41"]
                      ? formatNumber(
                          totals?.[LIGHT_ID]?.["41"].value,
                          undefined,
                          0
                        )
                      : "-"
                  }
                  extraValue={
                    totals?.[LIGHT_ID]?.["42"]
                      ? totals?.[LIGHT_ID]?.["42"].value
                      : undefined
                  }
                  footerText="тыс. чел., факт расселения"
                  footerLight={true}
                  fractionDigits={1}
                />
              </div>
              <div className={styles.textValueCount40}>
                <TextValue
                  valueSize="big"
                  value={
                    totals?.[LIGHT_ID]?.["40"]
                      ? formatNumber(
                          totals?.[LIGHT_ID]?.["40"].value,
                          undefined,
                          0
                        )
                      : "-"
                  }
                  footerText="тыс. чел., план расселения"
                  footerLight={true}
                  fractionDigits={1}
                />
              </div>
            </div>
          </div>

          <div className={styles.flexGrow}>
            <div className={styles.heading}>
              Основной объем финансирования, млн. руб.
              <div className={styles.flex}>
                <div className={styles.width50}>
                  <div className={styles.textValueCount}>
                    <TextValue
                      valueSize="big"
                      value={
                        data?.curr["43"]
                          ? formatNumber(data?.curr["43"].value, undefined, 0)
                          : "-"
                      }
                      footerText="дополнительные лимиты средств Фонда"
                      footerLight={true}
                      fractionDigits={1}
                    />
                  </div>
                </div>
                <div className={styles.width50}>
                  <div className={styles.textValueCount}>
                    <TextValue
                      valueSize="big"
                      value={
                        data?.curr["44"]
                          ? formatNumber(data?.curr["44"].value, undefined, 0)
                          : "-"
                      }
                      extraValue={
                        data?.curr["45"] ? data?.curr["45"].value : undefined
                      }
                      footerText="перечислено средств Фонда в регионы"
                      footerLight={true}
                      fractionDigits={1}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={clsx(styles.marginTop48, styles.heading)}>
              В том числе, дополнительный объем финансирования, млн. руб.
              <div className={styles.flex}>
                <div className={styles.width50}>
                  <div className={styles.textValueCount}>
                    <TextValue
                      valueSize="big"
                      value={
                        data?.curr["46"]
                          ? formatNumber(data?.curr["46"].value, undefined, 0)
                          : "-"
                      }
                      footerText="доп. лимит"
                      footerLight={true}
                      fractionDigits={1}
                    />
                  </div>
                </div>
                <div className={styles.width50}>
                  <div className={styles.textValueCount}>
                    <TextValue
                      valueSize="big"
                      value={
                        data?.curr["47"]
                          ? formatNumber(data?.curr["47"].value, undefined, 0)
                          : "-"
                      }
                      extraValue={
                        data?.curr["48"] ? data?.curr["48"].value : undefined
                      }
                      footerText="перечислено авансов по принятым заявкам на финансирование"
                      footerLight={true}
                      fractionDigits={1}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={clsx(styles.marginTop48, styles.heading)}>
              Перечислено регионами подрядчикам, млн. руб.
              <div className={styles.flex}>
                <div className={styles.width50}>
                  <div className={styles.textValueCount}>
                    <TextValue
                      valueSize="big"
                      value={
                        data?.curr["49"]
                          ? formatNumber(data?.curr["49"].value, undefined, 0)
                          : "-"
                      }
                      extraValue={
                        data?.curr["50"] ? data?.curr["50"].value : undefined
                      }
                      fractionDigits={1}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
