import { Spin } from "antd";
import clsx from "clsx";
import moment from "moment";
import { FC, useMemo } from "react";

import { formatNumber } from "../../../lib/number";
import { LIGHT_RATES } from "../../../redux/slices/lights/api/rate/rate";
import {
  DATE_DISPLAY_FORMAT,
  LIGHTS_MAP,
  LIGHTS_TYPES,
} from "../../../redux/slices/lights/api/scheme";
import { TextValue } from "../../TextValue/TextValue";
import * as styles from "../CardSubjectSummary.module.scss";
import { CardInfo } from "../components/CardInfo";
import { useCardSubjectSummary } from "../hooks/useCardSubjectSummary";
import { ICardSubjectSummaryProps } from "../scheme";

const LIGHT_TYPE = LIGHTS_TYPES.city;
const LIGHT_ID = LIGHTS_MAP[LIGHT_TYPE];

export const CardSubjectSummaryCity: FC<ICardSubjectSummaryProps> = ({
  id,
}) => {
  const { data, total, fetching } = useCardSubjectSummary(id, LIGHT_ID);

  const rate = useMemo(
    () => (dataVal: string) => {
      if (!data) {
        return undefined;
      }

      if (!data.curr[dataVal]) {
        return undefined;
      }

      return data.curr[dataVal].value > 0
        ? LIGHT_RATES.high
        : data.curr[dataVal].value < 0
        ? LIGHT_RATES.low
        : undefined;
    },
    [data]
  );

  if (fetching) {
    return (
      <div className={styles.loader}>
        <Spin />
      </div>
    );
  }

  return (
    <>
      <span className={styles.title}>
        Сведения предоставлены на{" "}
        {data?.curr["90"]
          ? moment(data.curr["90"].date).format(DATE_DISPLAY_FORMAT)
          : ""}
      </span>
      <div className={styles.cardsInfoWrapper}>
        <CardInfo
          value={total?.sn}
          description="Позиция субъекта в рейтингах"
          rate={total?.rate}
        />
        <CardInfo
          value={
            data?.curr["90"]
              ? formatNumber(data.curr["90"].value, undefined, 1)
              : "-"
          }
          description={`${
            data?.curr["90"]?.attributeVals.UNIT ?? ""
          }, утвержденные БА (ЛБО)`}
        />
        <CardInfo
          value={
            data?.curr["94"]
              ? formatNumber(data.curr["90"].value, undefined, 1)
              : "-"
          }
          description={`${
            data?.curr["94"]?.attributeVals.UNIT ?? ""
          }, заключены контракты`}
        />
        <CardInfo
          value={
            data?.curr["95"]
              ? formatNumber(
                  data.curr["95"].value,
                  data.curr["95"].attributeVals.UNIT,
                  1
                )
              : "-"
          }
          description="% контрактации"
        />
        <CardInfo
          value={
            data?.curr["91"]
              ? formatNumber(data.curr["91"].value, undefined, 1)
              : "-"
          }
          description={`${
            data?.curr["91"]?.attributeVals.UNIT ?? ""
          }, кассовое исполнение`}
        />
        <CardInfo
          value={
            data?.curr["92"]
              ? formatNumber(
                  data.curr["92"].value,
                  data.curr["92"].attributeVals.UNIT,
                  1
                )
              : "-"
          }
          description="% кассового исполнения"
          subRate={total?.rate}
        />
      </div>
      <div>
        <div className={clsx(styles.marginTop48, styles.heading)}>
          Общее количество территорий, шт.
        </div>
        <div className={styles.flex}>
          <div className={styles.width50}>
            <div className={styles.count}>
              {data?.curr["96"]
                ? formatNumber(data.curr["96"]?.value, undefined, 0)
                : "-"}
            </div>
            <span className={styles.info}>Общественные</span>
          </div>
          <div className={styles.width50}>
            <div className={styles.count}>
              {data?.curr["99"]
                ? formatNumber(data.curr["99"]?.value, undefined, 0)
                : "-"}
            </div>
            <span className={styles.info}>Дворовые</span>
          </div>
        </div>

        <div className={clsx(styles.marginTop48, styles.heading)}>
          Завершенное количество территорий, шт.
        </div>
        <div className={styles.flex}>
          <div className={styles.width50}>
            <div className={styles.textValueCount}>
              <TextValue
                valueSize="big"
                value={
                  data?.curr["97"]
                    ? formatNumber(data?.curr["97"].value, undefined, 0)
                    : "-"
                }
                extraValue={
                  data?.curr["98"] ? data.curr["98"].value : undefined
                }
                extraRate={rate("98")}
                footerText="Общественные"
                footerLight={true}
                fractionDigits={1}
              />
            </div>
          </div>
          <div className={styles.width50}>
            <div className={styles.textValueCount}>
              <TextValue
                valueSize="big"
                value={
                  data?.curr["101"]
                    ? formatNumber(data?.curr["101"].value, undefined, 0)
                    : "-"
                }
                extraValue={
                  data?.curr["102"] ? data.curr["102"].value : undefined
                }
                extraRate={rate("102")}
                footerText="Дворовые"
                footerLight={true}
                fractionDigits={1}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
